import { MODAL_CLOSE, MODAL_OPEN } from "../Actions/type";

const INITIAL_STATE = {
  isModalOpen: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MODAL_OPEN:
      return {
        ...state,
        isModalOpen: true
      };
    case MODAL_CLOSE:
      return {
        ...state,
        isModalOpen: false
      };
    default:
      return state;
  }
};
