import React, {useEffect, useState} from 'react';
import classes from "./SearchScreen.module.css";
import {useLocation} from "react-router-dom";
import LoadingSpinner from "../../Components/LoaderSpinner";
import axios from "axios";
import {API_URL} from "../../Utils/helpers/api_url";
import CommonMovieCard from "../../Components/CommonMovieCard/CommonMovieCard";
import {connect, useDispatch} from "react-redux";
import {updatedRentedList, updateWishlist} from "../../Redux/Actions/movies";
import RecentMoviesCrousel from "../../Components/RecentMoviesCrousel/RecentMoviesCrousel";

const loggedInId = localStorage.getItem('id');
const SearchScreen = () => {
    const {search} = useLocation();
    const searchParams = new URLSearchParams(search);
    const searchTerm = searchParams.get("st");
    const [movieList, setMovieList] = useState([]);
    const [isLoading, setIsLoading] = useState('false');

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateWishlist());
        dispatch(updatedRentedList());
    }, [dispatch]);


    const getMoviesBySearchTerm = async (searchTerm) => {
        setIsLoading(true)
        try {
            const result = await axios.get(`${API_URL}/api/v1/movies?search=${searchTerm}`);
            setMovieList(result.data.data?.movies || []);
            setIsLoading(false);
        } catch (e) {
            console.error("SearchScreen:getMoviesBySearchTerm: ", e);
        }
    }

    useEffect(() => {
        // issue side-effect
        getMoviesBySearchTerm(searchTerm);
    }, [searchTerm]);


    // getMoviesBySearchTerm();

    return (
        <div className="main-content mt-5">
            <div className="container mb-5 mt-5">
                {isLoading && <LoadingSpinner></LoadingSpinner>}
                {movieList.length > 0 &&
                    <div className="crousel-card-type-main-heading section-heading section-heading-band">
                        <h3>Found</h3>
                    </div>
                }
                <div className={classes.searchResultContainer}>
                    {movieList.map((item, index) => (
                            <CommonMovieCard
                                movieItem={item}
                                key={item.id}
                                loggedInId={loggedInId}
                            />
                        )
                    )
                    }
                </div>
                {movieList.length === 0 && <h5 className="section-heading mt-5 mb-5 text-center">We didn't find any matches for - {searchTerm}</h5>}
            </div>
            {movieList.length === 0 && <RecentMoviesCrousel heading="You may also like "/>}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        is_loading: state.movie_list.is_loading,
    };
};

export default connect(mapStateToProps, {
    updateWishlist,
    updatedRentedList
})(SearchScreen);