import React from "react";

const DhakadRating = (props) => {
  const getRating = (ratingIcon) => {
    const ratings = {
      Nuclear: 'Dhaakad rating 5',
      Roket: 'Dhaakad rating 3.5',
      Anar: 'Dhaakad rating 2',
      Sparkle: 'Dhaakad rating 1',
      Bomb: 'Dhaakad rating 3',
      Volcano: 'Dhaakad rating 4',
    };
    return ratings[ratingIcon] || 'Dhaakad rating 3';
  };
  return (
    <React.Fragment>
      <div className="dhakad-rating-sec">
        <div className="dhakad-rating-title-sec">
          <h6>Dhaakad Rating</h6>
          <img src={props.reatingIcon} alt="." />
          <span className="dhakad-rating-tooltip-btn">i</span>

          <div className="dhakad-rating-tooltip">
            <span>{getRating(props.rating)}</span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DhakadRating;
