import React from "react";
import "./AboutFounder.css"

const AboutFounder = () => {
  return (
    <React.Fragment>
      <div className=" about-container">
        <div className="container">
          <div className="about-wrapper">
            <div className="about-outer col-md-12">
              <div className=" col-md-12" style={{ marginTop:"4%", marginBottom:"2%", display:"flex", justifyContent:"center"}}>
                <h2 className="about-main-heading AboutFounderTitle">ABOUT FOUNDER </h2>
              </div>
              <div className="about-content">
                <div className="about-content-description">
                  <p className="about-description">
                  We invite you to connect directly with our founder, Earl Yadav.
                   His love and passion for movies led him to leave behind a glorious 
                   career in commercial flying. In his early years of preparing to become an actor,
                    he placed great emphasis on physical training, pushing himself to the limits to
                     enhance his flexibility, explosiveness, and muscularity, all in pursuit of becoming
                      the ultimate action star. He considers himself fortunate to have been able to accomplish 
                      incredible feats in the realm of action, from motorcycle stunts to calisthenics, boxing, 
                      and kickboxing—feats that perhaps only a select few actors in the world can match.
                  </p>
                  <p className="about-description">
                  As his knowledge of filmmaking expanded, Earl realized that his true calling lay in the art 
                  of storytelling through movies. He penned both "American Dream" and "ZIA," and coincidentally, 
                  the first movie he executive produced, "Lahore," went on to win numerous national and international awards.
                   His journey has been marked by determination, sacrifices, and an unwavering love for the craft of filmmaking.
                    Earl's story serves as a testament to the resilience and dedication required to bring creative visions to life.
                  </p>
                  <p className="about-description">
                  You can follow Earl on various social media platforms, such as Instagram (@Earl, Username: EarlYadav), Facebook,
                   Twitter, and YouTube, to stay updated on his projects and engage with him directly.
                  </p>

                  <p className="about-description">Visit our website at {<a href="https://www.dhaakadcinema.com/" target="blank">www.dhaakadcinema.com</a>} or download our application from the Google Play Store and Apple App
                     Store to explore our curated collection of must-watch movies and web series. Your support and feedback are
                      invaluable as we strive to create better movies for you. Leave your ratings and reviews to help us improve
                       and provide an exceptional cinematic experience.
                       </p>
                  <p className="about-description">Thank you for choosing Dhaakad Cinema, where you are the master of your entertainment choices.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AboutFounder;
