import React from 'react';
import "../../Helper/Style.css";
import CopyToClipboard from './CopyToClipboard';
import { REFERRAL_URL } from '../../../Utils/base';
import CloseIcon from "@material-ui/icons/Close";
import Refer  from '../../../Assets/Images/Refer-Modal.png'
import Whatsapp from "../../../Assets/Images/popup-Whatsap.svg"
import Facebook from "../../../Assets/Images/popup-Facebook.svg"
import Twitter from "../../../Assets/Images/popup-Twitter.svg"

function ReferPopup({referralCode,handleClose}) {

 const referralLink = `${REFERRAL_URL}${referralCode}`

    return (

      <div  style={{ zIndex:"999999999999",   position:"fixed" , display: "flex", alignItems:"center", justifyContent:"center", inset:"0px", background:"rgba(0, 0, 0, 0.5)"  , WebkitTapHighlightColor: "transparent"}} >
            <div className="popup-container">
<div className='d-flex '>
                <div className='pop-clo'>
                <div className='popup-btwn '>
              Refer Dhaakad Cinema to Friends and Family..
              </div>
             </div>
             <div style={{ cursor:"pointer"}} >
             <CloseIcon onClick={handleClose} className='popup-close'/>
</div>
             </div>
             
   
   <img className="popup-image" src={Refer} alt="ReferNow" />

   <p className='popup-para'>
    <span>"Share the excitement! Refer your family and friends to become part of our thriving community"</span>
   </p>
  

                <div className="input-container">
                    <input
                        label="Referal Code"
                        placeholder="Referal Code"
                        value={referralLink}
                        className="input-field"
                        disabled
                    />
                      <div  className='icon-popup'><CopyToClipboard textToCopy={referralLink}/></div>
                      
                </div>

 <div className='pop-imag'>
<img className="pop-img" src={Whatsapp} alt="Whatsapp" />
<img className="pop-img" src={Facebook} alt="Facebook" />
<img className="pop-img" src={Twitter} alt="Twitter" />


</div> 


            </div>
            </div>
    );
}

export default ReferPopup;
