import React from 'react';
import classes from './PrivacyPolicy.module.css';
import { NavLink } from "react-router-dom";

const PrivacyPolicy = () => {
    return (

        <div className="container mt-5">
            <div className={classes.privacyPolicyTitle} >
                PRIVACY POLICY
            </div>
            <div className="flex justify-content-center">
                <div className={classes.privacyPolicySubText} >
                    This Privacy Policy shall be read in conjunction and together with the <a href='/terms-and-conditions' target='blank'>Terms of Use / User Agreement</a> forms an integral part of the User Agreement between You ( “User(s) or you” ) and Dhaakad Cinema
                    (“We/Our/Us/Dhaakad Cinema”). Capitalized terms not defined herein shall have the meaning attributed to them under the <a href='/terms-and-conditions' target='blank'>Terms of Use / User Agreement</a>
                </div>
            </div>

            <div className={classes.level1Title}>1. Dhaakad Cinema Terms of Privacy Policy</div>
            <div className={classes.level1Text}>
                Privacy Policy describes how and when Dhaakad Cinema collects, processes, uses and shares your information when you use Dhaakad Cinema, including our mobile applications and websites
                (the "Services"). Dhaakad Cinema receives your information through our mobile applications, websites, email notifications, and other interactions with our Services. By accessing and/or using
                the Services, you consent and authorize us to the collection, transfer, processing, storage, disclosure and other uses of your information as described in this Privacy Policy. If you do
                not agree with any or all of the following terms of the Privacy Policy, please Do Not Use and/or access the Services
            </div>

            <div className={classes.level1Title}>2. Personal Data Collection and Use</div>
            <div className={classes.level1Text}>
                We use Personal Information to facilitate and improve our Services and communicate with you
                following existing regulations, while data integrity, accuracy, relevancy and legally
                justified processing are ensured. The data we collect depends on the context of your interactions
                with us, the choices you make, including your privacy settings, and the products and features
                listed below (collectively “Personal Information” or “Personal Data”):
            </div>

            <div className={classes.level2Title}>2.1 General Information</div>
            <div className={classes.level2Text}>The general data we collect can include SDK/API/JS code version,
                browser, Internet service provider, IP address, platform, timestamp, application identifier,
                application version, application distribution channel, independent device identifier,
                Android ad master identifier, network card (MAC) address, and international mobile device
                identification code (IMEI) the equipment model, the terminal manufacturer, the terminal device
                operating system version, the session start / stop time, the location of the language, the time
                zone and the network state (WIFI and so on)
            </div>

            <div className={classes.level2Title}>2.2 Finding Friends and Connecting to Other Services</div>
            <div className={classes.level2Text}>Services include several features to help connect you to the
                accounts of people you already know. For example, you may upload the information from your address book
                or connect your Dhaakad Cinema account to your account on another service such as Twitter. Dhaakad
                Cinema will match the information you provide with the contact information of other Dhaakad Cinema
                users in order to provide and improve the Services, for example by making recommendations and
                routing Dhaakad Cinema messages. You may remove address book data from Dhaakad Cinema at any time
                through the settings in your Dhaakad Cinema app. When you connect your Dhaakad Cinema account
                to other services, you are able to post your content to those services as well. You may disconnect
                your Dhaakad Cinema account from your account on the other services at any time. Your Dhaakad
                Cinema account will immediately revoke our authorization to cross-post on your behalf or
                otherwise access that account and we will delete information obtained from that connection (other
                than information that was publicly posted on Dhaakad Cinema, such as profile information),
                which may take some time.
            </div>

            <div className={classes.level2Title}>2.3 Content, Following, and other Public Information</div>
            <div className={classes.level2Text}>Dhaakad Cinema is an Over the Top (OTT) digital on demand Service
                which offers a wide range of content for entertainment purposes including without limitations,
                audio, visual, audiovisual, so most of the information you provide us is
                information that you choose to be made public. This includes not only the content that you post and
                data provided with such content, such as when it was posted, but also the accounts you follow, the
                content that you like or comment on, and other public interactions with the Services. Your public information is broadly and instantly disseminated. For instance, your profile information and public content is immediately delivered to a wide range of users and other services that access Dhaakad
                Cinema. When you share information or content via the Services, you should think carefully about
                what you are making public.
            </div>

            <div className={classes.level2Title}>2.4 Location Information</div>
            <div className={classes.level2Text}>In addition to profile information, you may also tell us your exact
                location if you choose to enable your computer or mobile device to send us location information. We
                may use and store information about your location to manage content copyright and exploitation
                restrictions applicable to your location and to also provide features of our Services, such as
                tagging a video with a location, and to improve and customize the Services, for example, with
                content that has been tagged near your location.
            </div>

            <div className={classes.level2Title}>2.5 Links</div>
            <div className={classes.level2Text}>We may keep track of how you interact with links across our
                Services, including our email notifications, third-party services, and client applications, by
                redirecting clicks or through other means. We do this to help improve our Services, for example to
                provide relevant advertising, and to be able to share aggregate click statistics such as how many
                times a particular link was clicked on.
            </div>

            <div className={classes.level2Title}>2.6 Cookies</div>
            <div className={classes.level2Text}>We may use "cookie" technology to collect additional website usage
                data and to improve our Services, but we do not require cookies for many parts of our Services such
                as viewing public videos. A cookie is a small data file that is transferred to your computer's hard
                disk. Dhaakad Cinema may use both session cookies and persistent cookies to better understand how
                you interact with our Services, to monitor aggregate usage by our users and web traffic routing on
                our Services, and to customize and improve our Services. Most Internet browsers automatically accept
                cookies. If you do not want cookies from Dhaakad Cinema or other websites, you can instruct your
                browser, by changing its settings, to stop accepting cookies or to prompt you before accepting a
                cookie from the websites you visit. However, some Services may not function properly if you disable
                cookies.
            </div>

            <div className={classes.level2Title}>2.7 Log Data</div>
            <div className={classes.level2Text}>The servers automatically record information ("Log Data") created by
                your use of the Services. Log Data may include information such as your IP address, browser type,
                operating system, the referring web page, pages visited, location, your mobile carrier, device and
                application IDs, search terms, and cookie information. We receive Log Data when you interact with
                our Services, for example, when you visit our websites, sign into our Services, view videos hosted
                by Dhaakad Cinema, or interact with our email notifications. Dhaakad Cinema uses Log Data to provide
                our Services and to measure, customize, and improve them. If not already done earlier, we will
                either delete Log Data or remove any common account identifiers, such as your username, full IP
                address, or email address, after 18 months or such other period as required by us.
            </div>

            <div className={classes.level2Title}>2.8 Other Non-Personal Identification Information</div>
            <div className={classes.level2Text}>We do not collect the "Personal Information" which can identify you
                or another person. We may collect non-personal identification information about Users whenever they
                interact with our apps. Non-personal identifying information may include the type of your phone,
                apps and other technical information about Users means of connection to our apps, such as the
                versions of phone model and other similar information.
            </div>

            <div className={classes.level2Title}>2.9 Third-Party Service</div>
            <div className={classes.level2Text}>Dhaakad Cinema uses a variety of third-party services to help
                provide our Services, including to host our various blogs and wikis and to help us understand the
                use of our Services. These third-party service providers may use cookies and other technologies to
                collect information about your use of our Services and other websites and services, including your
                IP address, device ID, pages viewed, and links clicked. Our service providers
                include Google Analytics and its demographics and interests reporting feature.
                Player also uses Facebook ad support: HTTPS: //www.facebook.com/business/ads-guide. Dhaakad Cinema also uses YouTube API Services in some of its products. That means if you are using a Dhaakad Cinema's service which makes use of YouTube APIs, then you are agreeing to the YouTube Terms of Service & Google Privacy Policy as well.
                Dhaakad Cinema also uses Google API through which Dhaakad Cinema collects information of the “Installed Application” in the Users Android based devices. <br />
                <span style={{ marginLeft: '5%' }}></span> Dhaakad Cinema also uses Google API through which Dhaakad
                Cinema collects information of the
                “Installed Application” in the Users android based devices.
            </div>

            <div className={classes.level2Title}>2.10 Interest-Based Ads</div>
            <div className={classes.level2Text}>In order to provide with a personalized advertisement experience to
                you, third parties, including Facebook, may collect or receive information from our app and other
                apps and use that information to improve services and targeted ads. On your Android, you can change
                your device settings to control whether you see online interest-based ads from Facebook and other
                companies. On your Android (versions 2.2 and up) and Google Play Services (versions 4.0 and up):
                <br />
                <br />
                · Go to Google Settings<br />
                · Tap Ads<br />
                · Tap Opt out of interest-based ads and tap OK
                <br />
                <br />
                Based on your choice on Admob ad personalization consent page, Admob & its partners will deliver
                personalized ads or non-personalized ads to you while using our Services.
            </div>


            <div className={classes.level1Title}>3. Sensitive Personal Data and Information</div>
            <div className={classes.level1Text}>
                Dhaakad Cinema will never ask you, and you must never provide Sensitive Personal Data Or Information to Dhaakad Cinema or to any
                person/entity representing Dhaakad Cinema. Any disclosure of Sensitive Personal Data Or Information
                shall be at your sole risk and without any liability to Dhaakad Cinema (including its directors, key
                managerial personnel, officers and employees). You understand, acknowledge and agree that Dhaakad
                Cinema or any other person acting on behalf of Dhaakad Cinema shall not in any manner be responsible
                for the authenticity of the Personal Information or Sensitive Personal Data or Information provided
                by you to Dhaakad Cinema.<br />
                You must note that any information that is freely available or accessible in public domain shall not
                be regarded as Personal Information or Sensitive Personal Data or Information for the purposes of
                this Privacy Policy and Dhaakad Cinema shall not be obliged to take any measures to protect the same
                since the same is freely available in the public domain. Please further note that identity theft and
                the practice currently known as "phishing" are of great concern to Dhaakad Cinema. We do not and
                will not, at any time, request your credit card information/debit card information/financial pin
                numbers and passwords.
            </div>

            <div className={classes.level1Title}>4. Information Sharing and Disclosure</div>
            <div className={classes.level1Text}>
                To improve our products and provide you with better service, We also share Personal Data with
                vendors or agents working on our behalf for the purposes described in this Privacy Policy. For
                example, companies we've hired to provide data analysis services may need collect and access to
                Personal Data to provide those functions. In such cases, these companies must abide by our data
                privacy and security requirements.
            </div>

            <div className={classes.level2Title}>4.1 Your Consent</div>
            <div className={classes.level2Text}>Dhaakad Cinema may share or disclose your information at your
                direction, such as when you choose to publicly post a video or update your profile information.
            </div>

            <div className={classes.level2Title}>4.2 Service Providers</div>
            <div className={classes.level2Text}>Dhaakad Cinema engages service providers to perform functions and
                provide services to us in India and abroad. We may share your Personal Information with such service
                providers subject to confidentiality obligations consistent with this Privacy Policy, and on the
                condition that the third parties use your Personal Data only on our behalf and pursuant to our
                instructions.
            </div>

            <div className={classes.level2Title}>4.3 Law and Harm</div>
            <div className={classes.level2Text}>Dhaakad Cinema may preserve or disclose your information if we
                believe that it is reasonably necessary to comply with a law, regulation or legal request; to
                protect the safety of any person; to address fraud, security or technical issues; or to protect
                Dhaakad Cinema’s rights or property. However, nothing in this Privacy Policy is intended to limit
                any legal defenses or objections that you may have to a third party's, including a government's,
                request to disclose your information.
            </div>

            <div className={classes.level2Title}>4.4 Business Transfers</div>
            <div className={classes.level2Text}>In the event that Dhaakad Cinema is involved in a bankruptcy,
                merger, acquisition, reorganization or sale of assets, your information may be sold or transferred
                as part of that transaction. The promises in this Privacy Policy will apply to your information as
                transferred to the new entity. By providing your information to us, you consent to any transfer and
                processing in accordance with this Privacy Policy.
            </div>

            <div className={classes.level2Title}>4.5 International Transfers</div>
            <div className={classes.level2Text}>If Dhaakad Cinema needs to host/ store its data which includes the User data as available with Dhaakad Cinema to servers falling out of User's jurisdiction, Dhaakad Cinema will take all reasonable efforts to ensure that the transfer and storage of such data to servers located outside the User's jurisdiction are bound by a duty of confidentiality
            </div>

            <div className={classes.level2Title}>4.6 Non-Private or Non-Personal Information</div>
            <div className={classes.level2Text}>Dhaakad Cinema may share or disclose your non-private, aggregated or
                otherwise non-personal information, such as your public user profile information, public posts, the
                people you follow or that follow you, or the number of users who clicked on a particular link (even
                if only one did)
            </div>


            <div className={classes.level1Title}>5. Age of Consent and Child Policy</div>
            <div className={classes.level1Text}>
                Our Services are primarily meant for the Users who have attained the age of 18 years or above and you
                have given us your consent to allow any of your minor dependents (less than 13 years) to use and
                access our Services. If you access our Services, then you shall be bound by
                our <a href="/grievance" target='blank'>Age Suitability Terms.</a>
            </div>

            <div className={classes.level1Title}>6. Our Policy Towards Children</div>
            <div className={classes.level1Text}>
                Our Services are not directed to minors who are under the age of 13. If you become aware that your
                child has provided us with Personal Information without your consent, please email
                to <a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=support@dhaakadcinema.com" target="_blank">support@dhaakadcinema.com</a>. We do not knowingly
                collect personal information from children under the age of 13. If we become aware that a
                child under the age of 13 has provided us with personal information, we take steps to
                remove such information and terminate the child's account
            </div>

            <div className={classes.level1Title}>7. Legal Basis for Personal Data Processing</div>
            <div className={classes.level1Text}>
                Dhaakad Cinema processes Personal Data for the purposes set out in this Privacy Policy, as described
                above. Our legal basis to process Personal Data includes processing that is: necessary for the
                performance of the contract with you (for example, to provide you with the services you request and
                to identify and authenticate you so you may use our Service); necessary to comply with legal
                requirements (for example, to comply with applicable accounting rules and to make mandatory
                disclosures to law enforcement); necessary for our legitimate interests (for example, to manage our
                relationship with you, to ensure the security of our services, to communicate with you about our
                products and services); and based on consent by our customers (for example, to place certain cookies
                and to share your information with third parties for advertising purposes).In some instances, you
                may be required to provide us with Personal Data for processing as described above, in order for us
                to be able to provide you all of our services, and for you to use all the features of our Services.
            </div>

            <div className={classes.level1Title}>8. Third Party Websites / Apps</div>
            <div className={classes.level1Text}>
                Our Service may contain links to other websites/services owned and operated by third parties which
                are not under the control of Dhaakad Cinema ("Third-Party Websites/Apps"). Any Third Party
                Websites/App you visit by a link from our Service is solely the responsibility of owners of such
                Third Party Websites/Apps. The content of, including materials and information contained on, any
                Third Party Websites/Apps to which you link from our Service is solely the responsibility of the
                owner and provider of such Third Party Websites/Apps. Any transactions that you enter into with a
                third party listed in this Service or linked from our Service are solely between you and
                owner/provider of such Third Party Websites/Apps. Dhaakad Cinema is and shall not be responsible for
                any such Third Party Websites/Apps, any products or services sold or provided by such Third Party
                Websites/Apps, nor the organizations publishing those Third Party Websites/Apps, and hereby disclaim
                any responsibility and liability for the same. The inclusion of any links does not constitute or
                imply an endorsement or recommendation by us of the Third Party Websites/Apps and the services and
                products offered therein, of the quality of any product or service, advice, information or other
                materials displayed, purchased, or obtained by you as a result of an advertisement or any other
                information or offer in or in connection with the Third Party Websites/Apps. Dhaakad Cinema shall
                not be liable, accountable or responsible for your Personal Information and/or Sensitive Personal
                Data or Information being used or misused by such Third Party Websites/Apps. access and usage of any
                Third Party Websites/Apps is entirely at your own risk. Dhaakad Cinema shall not be a party to any
                transaction between you and a Third Party Websites/Apps. Your use of a Third Party Websites/Apps is
                subject to the terms and conditions and privacy policy of such Third Party Websites/Apps in addition
                to our Terms of Use/User Agreement and Privacy Policy.
            </div>

            <div className={classes.level1Title}>9. Support</div>
            <div className={classes.level1Text}>
                If you do not consent to any part of the terms of this Privacy Policy or wish to withdraw consent to
                process Your Personal Data then you may stop using the Services and ask us to remove your Personal
                Data/ Information from our records by writing to our grievance
                officer <span className={classes.highlightColor}>Mr. Ankit Yadav</span> at <a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=ankityadav@dhaakadcinema.com" target="_blank">ankityadav@dhaakadcinema.com</a>.

            </div>

            <div className={classes.level1Title}>10. Changes to this Policy</div>
            <div className={classes.level1Text}>
                We may revise this Privacy Policy from time to time without any further notification (except for
                material changes). The most current version of the policy will govern our use of your Personal
                Information and will always be updated and available
                at <a href="/privacy-policy" target='blank'>https://www.Dhaakadcinema.in/privacy-policy.</a> If
                we make a change to this policy that, in our sole discretion, is material, we will notify you via
                an email to the email address associated with your account or through the Services. By continuing to
                access or use the Services after those changes become effective, you agree to be bound by the
                revised Privacy Policy.
            </div>

        </div>

    )
}

export default PrivacyPolicy;
