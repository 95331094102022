import React, {useState,useEffect } from "react";
import "../../Screen/SearchBox/index.css";
import {useHistory} from "react-router-dom";
import {checkValueInString} from "../../Utils/helpers/CustomUtils";

function SearchBox() {

  const [searchTerm, setSearchTerm] = useState("");
  const [enteredSearchTerm,setEnteredSearchTerm] = useState("");
  const [currentNavState,setCurrentNavState] = useState()
  const history = useHistory();

  useEffect(()=>{
    const path=window.location.pathname + window.location.search || '';
    setCurrentNavState(path);
    history.listen((location, action) => {
      /*console.log(`The current URL is ${location.pathname}${location.search}${location.hash}`)
      console.log(`The last navigation action was ${action}`)*/
      if(!location.pathname.includes('/search')){
        setCurrentNavState(location.pathname);
      }
    })
  },[]);

  // this effect is to deffer the user input, otherwise api call goes on every keystroke
  useEffect(() => {
    const timeOutId = setTimeout(() => setSearchTerm(enteredSearchTerm), 500);
    return () => clearTimeout(timeOutId);
  }, [enteredSearchTerm]);

  useEffect(()=>{
    if(checkValueInString(searchTerm)){
      history.push({
        pathname: '/search',
        search: '?st='+searchTerm
      })
    }else{
      if(currentNavState){
        history.push(currentNavState);
      }
    }
  },[searchTerm])


  const handleChange = (e) => {
    setEnteredSearchTerm(e.target.value);
  };

  return (
    <React.Fragment>
      <div className="dropdown search-toggler-box">
        <input
          className="form-control input-open"
          type="text"
          onChange={handleChange}
          placeholder="Search"
          id="dropdownMenuLink"
          autoComplete="off"
        />
      </div>
    </React.Fragment>
  );
}

export default SearchBox;
