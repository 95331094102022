import React, {useState, useEffect} from "react";
import BottomNavbar from "../../Components/Helper/BottomNavbar";
import classes from './Wishlist.module.css';
import {toast} from "react-toastify";
import axios from "axios";
import {API_URL} from "../../Utils/helpers/api_url";
import Crousel from "../../Components/Helper/Crousel";
import CommonMovieCard from "../../Components/CommonMovieCard/CommonMovieCard";
import RecentMoviesCrousel from "../../Components/RecentMoviesCrousel/RecentMoviesCrousel";
import {connect, useDispatch, useSelector} from "react-redux";
import {updatedRentedList, updateWishlist} from "../../Redux/Actions/movies";

const loggedInId = localStorage.getItem('id');
const Wishlist = () => {

    const [wishlistedMovies, setWishlistedMovies] = useState([]);
    // init the wishlist
    const wishlist = useSelector((state) => state.movie_list.wishlist);
    useEffect(() => {
        getWishListedMoviesAndSet()
    }, [wishlist]);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(updatedRentedList());
    }, [dispatch]);


    const getWishListedMoviesAndSet = async () => {
        const userId = localStorage.getItem('id');
        if (userId) {
            try {
                const axiosResult = await axios.get(`${API_URL}/api/v1/users/${userId}/wishlist`);
                if (axiosResult.data.data) {
                    setWishlistedMovies(axiosResult.data.data.whishlist);
                } else {
                    toast.error('Finding wishlist error')
                }
            } catch (e) {
                console.error('Wishlist:getWishlistedMoviesAndSet: ', e);
                toast.error('Unable to get wishlist');
            }
        }
    }

    // loading
    const loggedIn = () => {
        /*if (Is_loading === true) {
            return <LoadingSpinner />;
        }*/
    };
    return (
        <>
            <div className="main-content">
                <BottomNavbar className="mt-5"/>
                {loggedIn()}
                {wishlistedMovies?.length === 0 && wishlist.length===0 && <h5 className="ms-5 mb-5 text-center">You have not wishlisted any movies recently</h5>}
                {wishlistedMovies?.length === 0 && wishlist.length===0 && <div>
                    <RecentMoviesCrousel heading="You may also like"/>
                </div>}
                {wishlistedMovies?.length>0 &&
                    <Crousel infinite={wishlistedMovies?.length >= 4} >
                        {
                            wishlistedMovies?.map((item, index) => {
                                if(!item.id){
                                    item.id = item._id
                                }
                                return (
                                    <CommonMovieCard
                                        movieItem={item}
                                        key={item.id}
                                        isMinHeight={true}
                                        loggedInId={loggedInId}
                                    />
                                )
                            })
                        }
                    </Crousel>
                }
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        is_loading: state.movie_list.is_loading,
    };
};

export default connect(mapStateToProps, {
    updateWishlist,
    updatedRentedList,
}) (Wishlist);
