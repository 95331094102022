import React from "react";
import ModalLayout from "../../ModalLayout";
import axios from "axios";
import {API_URL} from "../../../Utils/helpers/api_url";
import { useHistory } from "react-router-dom";

const StrModal = ({modalId, movieId,transId}) => {
    const history = useHistory();
    const onPlayNowClick = () => {
        history.push(`/movie/watch/${movieId}`);
        axios.patch(`${API_URL}/api/v1/trasncations/${transId}`, {
            streamed: true,
            startedAt: new Date(),
        }).then((result) => {
            console.log(`StreamModal::axios result  - `, result.data);
        }).catch((error) => console.error("error:onPlayNowClick", error));
    }

    return (
        <React.Fragment>
            <ModalLayout
                modalId={modalId}
                modalTitle={""}
                className="streamModalOuter"
            >
                <div className="str-warning p-4">
                    <h4>You can watch this movie unlimited times for a period of 7 days once you start playback.</h4>
                </div>
                <div className="d-flex justify-content-around px-4 py-3">
                    <button
                        className="custom-Str-btn out-line-btn"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    >
                        Not Now
                    </button>
                    <button
                        type="button"
                        className="custom-Str-btn"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={onPlayNowClick}
                    >
                        Play Now
                    </button>
                </div>
            </ModalLayout>
        </React.Fragment>
    );
};

export default StrModal;
