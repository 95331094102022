import React from 'react';
import "./RefundPolycy.css"

function RefundPolycy() {
  return (
 <div className='heightrefund'>
     <div className="margin">
         <div className='sidespace'>

      

   <div className='refback '>
    <div className='align'>
    Refund Policy
    </div>
   </div>

   <p className='paraRefund'>Once any content has been ordered, the order cannot be canceled, nor shall be in any refunds. Dhaakad Cinema reserves the right to modify the lineup/
Validity period for any content available on its platform without prior notice.</p>
    </div>
    </div>
    </div>
  )
}

export default RefundPolycy