import React from 'react'
import "../Helper/Style.css";
import ReferImage from '../../Assets/Images/Refer-Image.png'; 
import { Link } from 'react-router-dom';
import OTPLogin from './Modal/OTPLogin';
import {connect, useDispatch, useSelector} from "react-redux";
import { MODAL_OPEN,MODAL_CLOSE } from "../../Redux/Actions/type";
function Refer({openReferModal}) {

  const dispatch = useDispatch();

  const token = localStorage.getItem("token");
    return (
        <React.Fragment>   
              <div className=" refernow-body">
                  <div className="refer-now h3  ">
                    <h3>
                    <span> Refer Dhaakad Cinema to Friends</span> <span> and Family... </span> </h3>
                      <p> <span>Let's build our own community to empower credible </span>
                      <span>movies, webseries, plays & stand-up comedies.</span></p>
                    {token ? <button onClick={openReferModal}  className=' hover-green '>Refer Now</button>: 
                 <button  onClick={(e) => {
                  dispatch({
                      type: MODAL_OPEN,
                    });
                  e.preventDefault()
              }} className=' hover-green  button-refer'>Refer Now </button>    }
                   
                    

                  </div>
                 
                  
                  <img className="image-size" src={ReferImage} alt="ReferNow" />
                
                  </div>
           
           
          
          
        </React.Fragment>
  )
}

export default connect(null, {MODAL_OPEN,MODAL_CLOSE})(Refer) ;









