import React, { useState } from 'react';
import { MdContentCopy } from 'react-icons/md';
import '../profile.css'
import CopyText from "react-copy-text";
 // Assuming you have 'react-icons' installed

const CopyToClipboard = ({ textToCopy }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      setIsCopied(true);

      // Reset the "Copied" message after a short delay
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    } catch (error) {
      console.error('Error copying to clipboard:', error);
    }
  };

  return (
    <>
    <div className='icon1' onClick={handleCopyClick}>
      <MdContentCopy />
    </div>
    {isCopied && <p className='copiedtext' style={{ display: 'inline' }}>Copied</p>}
  </>
  
  )
};



export default CopyToClipboard;
