export const IS_LOADING = "IS_LOADING";
export const MOVIE_LIST = "MOVIE_LIST";
export const IS_FEATURED = "IS_FEATURED";
export const IS_TRANDING = "IS_TRANDING";
export const MOVIES_BY_LANGUAGES = "MOVIES_BY_LANGUAGES";
export const MOVIE_BY_ID = "MOVIE_BY_ID";
export const COMING_SOON_MOVIE = "COMING_SOON_MOVIE";
export const RECENT_ADDED_MOVIE = "RECENT_ADDED_MOVIE";
export const RELATED_MOVIE_LIST = "RELATED_MOVIE_LIST";
export const PRE_BOOKED_MOVIE = "PRE_BOOKED_MOVIE";
export const RENTED_MOVIE_LIST = "RENTED_MOVIE_LIST";
export const PURCHASED_LIST = "PURCHASED_LIST";
export const PURCHASED_LIST_FIELD = "PURCHASED_LIST_FIELD";
export const STREAMING_LIBRARY = "STREAMING_LIBRARY";
export const UPDATE_STREAM_MOVIE = "UPDATE_STREAM_MOVIE";
export const SEARCH_MOVIE = "SEARCH_MOVIE";
export const CAST_BY_ID = "CAST_BY_ID";
export const LANGUAGES = "LANGUAGES";
export const SIGN_UP = "SIGN_UP";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const VERIFY_PASSWORD = "VERIFY_PASSWORD";
// export const LOGIN_WITH_GOOGLE = "LOGIN_WITH_GOOGLE";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const CREATE_NEW_PASSWORD = "CREATE_NEW_PASSWORD";
export const GET_USER = "GET_USER";
export const EDIT_USER = "EDIT_USER";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";

export const COUPONS_LIST = "COUPONS_LIST";
export const ADD_REVIEW = "ADD_REVIEW";
export const REVIEW_LIST_BY_MOVIE = "REVIEW_LIST_BY_MOVIE";
export const ADD_USER_MOVIE = "ADD_USER_MOVIE";
//
export const ADD_TO_CART = "ADD_TO_CART";
export const DELETE_ITEM = "DELETE_ITEM";
export const CREATE_ORDER = "CREATE_ORDER";
export const GET_USER_BY_EMAIL = 'GET_USER_BY_EMAIL';

export const CURRENT_USER_MOVIE_TIME = 'CURRENT_USER_MOVIE_TIME';

export const UPDATE_USER_WISHLIST = 'UPDATE_WISHLIST';
export const UPDATE_USER_RENTEDLIST = 'UPDATE_RENTEDLIST';
export const MODAL_OPEN = "MODAL_OPEN"
export const MODAL_CLOSE = "MODAL_CLOSE"