import React from 'react';
import classes from './FAQ.module.css';
import {NavLink} from "react-router-dom";

const FAQ = () => {
    return (
        
            <div className="container" style={{ marginTop:"4%", marginBottom:"2%"}}>
                <div className={classes.faqTitle}>REGISTERING WITH DHAAKAD CINEMA </div>
                <div className="flex flex-row justify-content-center mt-4">
                    <div className={classes.faqSubText}>Signing up with DHAAKAD CINEMA is easy. You can sign up with
                        DHAAKAD
                        CINEMA with your mobile number or email ID. You need to log in to rent and watch a movie/web
                        series.
                    </div>
                </div>
                <div className={classes.faqMainText}>
                    <NavLink to="/register">Click here</NavLink> to register.
                    <br/>
                    <br/>
                    If you're signing up for DHAAKAD CINEMA using your mobile number or email ID,
                    you will be required to provide your first & last name, set a password, share your Date of Birth,
                    Gender and agree to our terms of use & privacy policy. Mobile registrations will have an additional
                    step of verifying OTP.
                    <br/>
                    <br/>
                    <span className={classes.faqMainTextBold}>Benefits of registering</span>
                    <br/>
                    There's plenty of benefits to avail by registering with us. Such as:
                    <br/>
                    <br/>
                    1. Watch a video from where you left, and switch easily across devices too!
                    <br/>
                    2. Download videos for viewing later.
                    <br/>
                    3. Save videos to your watchlist and favorite them too.
                    <br/>
                    4. Set reminders for your favorite shows.
                    <br/>
                    5. Receive personalized recommendations and communication basis your watch history
                    <br/>
                    If you're facing difficulties when trying to register with us, please
                    contact <a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=support@dhaakadcinema.com" target="_blank">support@dhaakadcinema.com</a>
                </div>

                <div className={classes.faqLevel1Title}>
                    Renting movies/web series on Dhaakad Cinema
                    <br/>
                    (currently only Full HD and No 4k streaming)
                </div>

                <div className={classes.faqPoints}>
                    1. RENTAL VALIDITY: You have a specific number of days to start playing the movie once rented. This
                    may differ for each movie.
                    <br/>
                    2. WATCH TIME VALIDITY: You have a specific number of hours to finish watching the movie after you
                    start playing. This may differ for each movie.
                    <br/>
                    3. Once the movie/web series rental or watch time validity has expired, you will need to rent the
                    movie/webseries to watch it again.
                </div>


                <div className={classes.faqQuestion}>Q. Why Dhaakad Cinema?</div>
                <div className={classes.faqAnswer}><span className={classes.faqAnswerChar}>
                    A.&nbsp;</span> An OTT platform was desperately needed where independent creators are able to 
                    showcase their creativity and earn revenue without any prejudice or bias. One doesn’t need to 
                    be a star director/filmmaker/producer or have an endorsement of a big production house to 
                    stream their creation.
                    Despite numerous pursues with traditional theater, distributor and available OTT platform the 
                    founder Earl Yadav couldn’t find any fair deal to stream his short films and feature film. 
                    Hence he decided to make Dhaakad Cinema.
                </div>


                <div className={classes.faqQuestion}>Q. Why audience will pay for the content?</div>
                <div className={classes.faqAnswer}><span className={classes.faqAnswerChar}>
                    A.&nbsp;</span> All the content available of Dhaakad Cinema platform is purely judged on merit.
                     Merit and merit only. By paying for the content the audience is directly contributing to the 
                     earning of the independent filmmaker and thus encouraging production of more quality content
                      and supporting new talent(cast and crew).
                      
                </div>
                <div className={classes.faqAnswer}>The audience saves on time to track quality content and is actually investing in a noble cause. </div>

                <div className={classes.faqQuestion}>Q. How to watch content on Dhaakad Cinema?</div>
                <div className={classes.faqAnswer}><span className={classes.faqAnswerChar}>
                    A.&nbsp;</span>DHAAKAD CINEMA
                    consumers can easily discover latest must watch independent movies, web series, comedy shows & plays.
                     We are the first online theatre in India and as such we aim to keep content which audience are willing to pay for.
                </div>


                <div className={classes.faqQuestion}>Q. How to watch/stream?</div>
                <div className={classes.faqAnswer}><span className={classes.faqAnswerChar}>
                    A.&nbsp;</span>On clicking rent, you will be guided to login or register with
                 DHAAKAD CINEMA. This will be followed by a list of supported payment options where
                  you can complete the transaction and start enjoying the content.
                </div>
                <div className={classes.faqAnswer}>Note that stipulated amount of time will be given to start watching that particular content as 
                this is a pay-per-view service (pack validity). Also, once you’ve started watching, the session will last for limited hours (watch time validity).
                 You can pause and resume the content multiple times only within watch time validity. Furthermore, content will be available for download or offline 
                 viewing for the particular time period mentioned above. Recording/transmitting the content in any manner is strictly prohibited and will make you 
                 liable for stringent legal action. Watch time validity will be considered as per applicable local time in your country of viewing.</div>

                <div className={classes.faqQuestion}>Q. How to buy/rent content?</div>
                <div className={classes.faqAnswer}><span className={classes.faqAnswerChar}>A.&nbsp;</span>
                    Once you have clicked the ‘Rent’ button for the content offered, you will be presented with multiple
                    payment options. The payment options for are UPI, Credit/Debit Card, Net Banking and Wallet. As a
                    pay-per-view service, additional viewing of content will require further purchase. The transaction
                    will be non-refundable, non-transferrable and cannot be carried forward for any reason once the
                    watch time validity ends. Each transaction is unique to individual subscriber and cannot be shared
                    with any other person.
                </div>

                <div className={classes.faqQuestion}>Q. How will I know about the validity left for viewing content
                    after transaction?
                </div>
                <div className={classes.faqAnswer}><span className={classes.faqAnswerChar}>A.&nbsp;</span>
                    A confirmation message (SMS & Email) will be sent to you
                    after a successful transaction. This will inform you about the pack and watch time validity.
                    This information will also be visible on app and web once the transaction is completed.
                    Watch time validity shall be within the local time zone in your country of access.
                </div>

                <div className={classes.faqQuestion}>Q. Can I watch content on multiple devices?</div>
                <div className={classes.faqAnswer}><span className={classes.faqAnswerChar}>A.&nbsp;</span>
                    {/*<span className={classes.highlightColor}>
                        After renting the content, it can be watched across 2 devices however, at any given point, you can
                    watch it on only one single device. You can start viewing, pause and resume on your second device
                    only if you have logged in using the right credentials and your watch time validity has not expired.
                    The start time and end time will remain unchanged for both devices.
                    </span>*/}
                    Yes
                </div>

                <div className={classes.faqQuestion}>
                    Q. Can I purchase/rent movies in advance?</div>
                <div className={classes.faqAnswer}><span className={classes.faqAnswerChar}>
                    A.&nbsp;</span>
                    The rental / transaction for content will be possible only once it has been released and is made
                    available on DHAAKAD CINEMA Platform
                </div>

                <div className={classes.faqQuestion}>
                 Q. Can I cancel my transaction?</div>
                <div className={classes.faqAnswer}><span className={classes.faqAnswerChar}>
                    A.&nbsp;</span>
                    Once you have rented content, the amount cannot be refunded or redeemed against any other benefit.
                </div>
                <div className={classes.faqAnswer}>
                <span className={classes.faqbold}> Once any Content has been ordered , the order cannot be canceled nor shall there be any refunds. </span>
             <span> Dhaakad Cinema reserves the right to modify the lineup / validity period for any Content available on its platform without prior notice.</span> 
             </div>


                <div className={classes.faqQuestion}>
                    Q. Where can I find the recent movie I’ve rented?
                </div>
                <div className={classes.faqAnswer}><span className={classes.faqAnswerChar}>
                    A.&nbsp;</span>
                    Please login with your credentials and go to your profile
                    click on <NavLink to="/rented-movies">rented movies</NavLink>
                </div>

                <div className={classes.faqQuestion}>
                    Q. How can I check the time remaining to stream my movie?
                </div>
                <div className={classes.faqAnswer}><span className={classes.faqAnswerChar}>
                    A.&nbsp;</span>
                    Yet to be implemented</div>

                <div className={classes.faqQuestion}>
                    Q. Where are my online downloaded videos?
                </div>
                <div className={classes.faqAnswer}><span className={classes.faqAnswerChar}>
                    A.&nbsp;</span>
                    Yet to be implemented</div>
                {/*<div className={classes.faqAnswer}>You will be able to see the online downloaded videos by tapping on
                    the download icon available on the top right corner of the screen. Alternatively, you can access it
                    via the navigation menu> select “My downloads” available beneath your profile.
                </div>*/}

                <div className={classes.faqQuestion}>
                    Q. Why does the app consumes so much data while watching online?
                </div>
                <div className={classes.faqAnswer}><span className={classes.faqAnswerChar}>
                    A.&nbsp;</span>
                    Yet to be implemented</div>
                {/*<div className={classes.faqAnswer}>To reduce data usage while watching content, kindly tap on the
                    Navigation menu available on the app > Click on settings> and Enable the Data Saver option.
                </div>*/}

                <div className={classes.faqQuestion}>
                    Q. Can't install app due to insufficient space.
                </div>
                <div className={classes.faqAnswerpoints}><span className={classes.faqAnswerChar}>
                    A.&nbsp;</span>
                    <ul className={classes.faqAnswerList}>
                        <li>Dhaakad Cinema requires up to 20MB of INTERNAL storage space, Android
                            will not install any app on SD card regardless of it having enough storage. You can move it
                            to SD
                            card later but this will be installed on INTERNAL space first.
                        </li>
                        <li>
                            Free internal storage space capacity can be found on Home > Settings > Apps > space gauge at
                            the
                            bottom. You can remove some other apps to increase free internal storage space if necessary.
                        </li>
                    </ul>
                </div>

                <div className={classes.faqQuestion}>
                    Q. I am facing video playback issues. What do I do?
                </div>
                <div className={classes.faqAnswerpoints}><span className={classes.faqAnswerChar}>
                    A.&nbsp;</span>
                    <ul className={classes.faqAnswerList}>
                        <li>Step 1: First, let’s make sure you are connected to the internet. If not, please check your
                            internet
                            connection.
                        </li>
                        <li>Step 2: If the app shows an error, then send it to us. We will get back to you asap!</li>
                        <li>
                            Step 3: If it still doesn’t work then please share the following details to –
                            <a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=support@dhaakadcinema.com" target="_blank">support@dhaakadcinema.com</a>
                            {/*<span className={classes.highlightColor}>support@dhaakadcinema.com</span>*/}
                            <ul>
                                <li>your device information</li>
                                <li>your internet service provider</li>
                                <li>Detail of the video you were watching</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div className={classes.faqQuestion}>
                    Q. Settings are not remembered. / Last played video or finished videos are not displayed in
                    different colour. The "What's New" message appears whenever launching the player.
                </div>
                <div className={classes.faqAnswerpoints}><span className={classes.faqAnswerChar}>
                    A.&nbsp;</span>
                    <ul className={classes.faqAnswerList}>
                        <li> This can happen when Player can't write to internal data file. Please clear app data and
                            try again.
                        </li>
                        <li>App data can be cleared in Home > Menu > Settings > Apps > {<a href="https://www.dhaakadcinema.com/" target="blank">Dhaakad Cinema</a>}  > Clear data.</li>
                    </ul>
                </div>                     

                <div className={classes.faqQuestion}>
                    Q. There is a bug in the app. Where should I tell?
                </div>
                <div className={classes.faqAnswerpoints}><span className={classes.faqAnswerChar}>
                    A.&nbsp;</span>
                    <ul className={classes.faqAnswerList}>
                        <li> Please upload your bug report to <a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=support@dhaakadcinema.com" target="_blank">support@dhaakadcinema.com</a>
                        </li>
                        <li>System log is needed when the bug crashes your player.</li>
                        <li>If you know that specific video file or subtitle file cause a problem, please send us
                            that file or URL where we can download that file. We will do our best to take care of the
                            issue.
                        </li>
                    </ul>
                </div>

                <div className={classes.faqQuestion}>
                    Q. I did a factory reset on my phone and it's now wanting me to pay for movie again but I already did.
                </div>
                <div className={classes.faqAnswerpoints}><span className={classes.faqAnswerChar}>
                    A.&nbsp;</span>

                    <ul className={classes.faqAnswerList}>
                        <li> License is per account and is validated through Google Play Services in case of Google Play
                            purchases. In case you logged in with the correct account but still getting prompts to pay
                            again, it's likely that Google Play Service Framework on your device is out of sync. Since
                            this is an issue with Google Play Store, we have limited scope for improvement. However, we
                            recommend trying the following and it may help fix the problem.
                        </li>
                        <li>Remove your Google account from the device.</li>
                        <li>Clear the app data of Google Play Store and Google Play Services.</li>
                        <li>Restart the device</li>
                        <li>Add the account again</li>
                    </ul>
                </div>

              
              
            
            </div>
      
    )
}

export default FAQ;
