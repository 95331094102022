import React from 'react'
import "../Helper/Style.css";
import dhakadVdo from '../../Assets/Images/dhakadVdo.svg'; 
import DemoVedio from "../../Assets/Images/HomeDemoVideo.mp4";
import { Link } from 'react-router-dom';
import OTPLogin from './Modal/OTPLogin';
import {connect, useDispatch, useSelector} from "react-redux";
import { MODAL_OPEN,MODAL_CLOSE } from "../../Redux/Actions/type";
function whyDhakad({openReferModal}) {

//   const dispatch = useDispatch();

  const token = localStorage.getItem("token");
    return (
        <React.Fragment>
                <div className=''>
                    <div className='section-heading section-heading-band'>
                            <h3> Why Dhaakad Cinema ? </h3>
                    </div> 
                    <div className='MissonBody '> 
                       
                            <div className='whyDhakad'> <ul> <li> Mission to spotlight exceptional films, web series, plays, and comedy shows. </li>
                          <li> Goal is to provide a voice and platform for talented filmmakers whose movies/web series may not have the
                             star power or backing of big production houses to secure traditional theater screenings or licensing deals with major OTT platforms. </li> 
                         <li>    You, the audience deserve exceptional content and only you should have the power to decide and  create new stars.</li>
                         </ul> </div> 
                        
                        <div className="dhakadimg">
                        <video className="dhaakadvedio" src={DemoVedio} controls  /> 
                        </div>
                    </div>                
                </div>   
        </React.Fragment>
  )
}

export default connect(null)(whyDhakad) ;









