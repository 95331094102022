import React from "react";
import CheckIcon from "@material-ui/icons/Check";
import AddIcon from "@material-ui/icons/Add";
import axios from "axios";
import {API_URL} from "../../Utils/helpers/api_url";
import {toast} from "react-toastify";
import Tooltip from '@material-ui/core/Tooltip';
import {connect, useDispatch, useSelector} from "react-redux";
import {updateWishlist} from "../../Redux/Actions/movies";

const AddToWishListBtn = (props) => {
    const dispatch = useDispatch();
    const wishlist = useSelector((state) => state.movie_list.wishlist);
    const isAdded = wishlist.includes(props.movieId);

    const onBtnClick = async (event) => {
        event.stopPropagation();
        try {
            if (isAdded) {
                const result = await axios.delete(`${API_URL}/api/v1/users/${props.loggedInId}/wishlist/${props.movieId}`, {});
                if (result.data) {
                    toast.success('Wishlist item deleted');
                    dispatch(updateWishlist());
                } else {
                    toast.error('Failed to delete wishlist');
                }
            } else {
                const result = await axios.patch(`${API_URL}/api/v1/users/${props.loggedInId}/wishlist/${props.movieId}`, {});
                if (result.data) {
                    toast.success('Wishlist updated');
                    dispatch(updateWishlist());
                } else {
                    toast.error('Failed to update wishlist');
                }
            }
        } catch (e) {
            console.error('AddToWishListBtn:onBtnClick: failed ', e);
            toast.error(`Failed to ${isAdded ? 'remove from ' : 'add to'} wishlist`);
        }

    }
    return <Tooltip title={isAdded?"Remove from wishlist":"Add to wishlist"}>
        <button className={'icon-btn ' + props.className}
                onClick={onBtnClick}>
            {isAdded ? <CheckIcon className={props.iconClassName}/> : <AddIcon className={props.iconClassName}/>}
        </button>
    </Tooltip>;

}

const mapStateToProps = (state) => ({
    is_loading: state?.movie_list?.is_loading,
});

export default connect(mapStateToProps, {updateWishlist})(AddToWishListBtn);