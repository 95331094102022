import React, {useState} from "react";
import {Modal} from "react-bootstrap";
import VideoJSPlayer from "../VideoPlayer/VideoJSPlayer";
import videojs from "video.js";
import CloseIcon from "@material-ui/icons/Close";

const FullScreenTrailer = (props) => {
    let videoType = 'video/mp4';
    if(props.trailerLink && props.trailerLink.includes('m3u8')){
        videoType = 'application/x-mpegURL';
    }
    const videoJsOptions = {
        autoPlay: true,
        muted:false,
        controls: true,
        responsive: true,
        fluid: true,
        sources: [{
            src: props.trailerLink,
            type: videoType
        }]
        // for hls stream
        /*sources: [{
            src:'https://cdn.flowplayer.com/a30bd6bc-f98b-47bc-abf5-97633d4faea0/hls/de3f6ca7-2db3-4689-8160-0f574a5996ad/playlist.m3u8',
            type: 'application/x-mpegURL'
        }]*/
    };
    const playerRef = React.useRef(null);
    const handlePlayerReady = (player) => {
        playerRef.current = player;
        // props.onPlayerReady(player);
        setTimeout(() => {
            player.play()
        }, 500)

        // You can handle player events here, for example:
        player.on('waiting', () => {
            videojs.log('player is waiting');
        });

        player.on('dispose', () => {
            videojs.log('player will dispose');
        });
    };
    return <>
        <Modal show={props.show} onHide={props.handleClose} dialogClassName="full-screen-trailer-modal">
            <div className="full-screen-trailer-modal-body">
                <div className="full-screen-trailer-close-btn">
                    <CloseIcon onClick={props.handleClose} style={{cursor:'pointer',height:'40px',width:'40px',color:'rgba(255,255,255,0.42)'}}/>
                </div>
                <div className="full-screen-trailer-player">
                    <VideoJSPlayer options={videoJsOptions} onReady={handlePlayerReady} className="w-100 h-100" videoStyle={{paddingTop:'0'}}/>
                </div>
            </div>
        </Modal>
    </>

}

export default FullScreenTrailer;