import React from "react";
import UniqueImg from "./Images";

const MovieDetailBanner = (props) => {
  return (
    <React.Fragment>
        <div className="slider-banner cursor-pointer">
        <img
         src={props.BannerSrc}
          className="swiper-carousel-main-banner"
          alt="Images"
        />
     
      <div className="slider-content movie-banner">
        <div className="container-carousel">
          <div className="slider-content-outer">

            <div className="row">

              <div className="col-sm-5">
                <div
                  className="movie-detail-wrapper movie-detail-content"
                  id="customizeswiper">
                  {props.children}
                </div>
              </div>

              <div className="col-sm-7 slider-cards">
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </React.Fragment>
  );
};

export default MovieDetailBanner;











{/* <div className="cont-slide movie-banner">{props.children}</div> */}