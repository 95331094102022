import React, { useState } from "react";
import "../../Helper/Style.css";
import { Formik } from "formik";
import { loginUser, registerFirebaseUser } from "../../../Redux/Actions/auth";
import { useDispatch } from "react-redux";
import ModalBtn from "../../ModalBtn";
import { NavLink, useLocation } from "react-router-dom";
import firebaseHelper from "../../../Utils/helpers/firebase-helper";
import { FaFacebook } from "@react-icons/all-files/fa/FaFacebook";
import { FcGoogle } from "@react-icons/all-files/fc/FcGoogle";

export function Login(props) {
    const dispatch = useDispatch();
    const [passwordType, setPasswordType] = useState("password");
    const [incorrectPass, setIncorrectPass] = useState(false);
    const location = useLocation();
    let redirectPath = '/';
    if (location.pathname.includes('movie-detail')) {
        redirectPath = location.pathname
    }

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text");
            return;
        }
        setPasswordType("password");
    };
    const signInWithFacebook = async (event) => {
        event.stopPropagation();
        const result = await firebaseHelper.FacebookAuth();
        dispatch(registerFirebaseUser({ email: result.user.email, userType: 'user', firebaseToken: result.user.accessToken, firebaseUID: result.user.uid }, true));

    };

    const signInWithGoogle = async (event) => {
        event.stopPropagation();
        const result = await firebaseHelper.GoogleAuth();
        dispatch(registerFirebaseUser({
            email: result.user.email, userType: 'user', firebaseToken: result.user.accessToken,
            firebaseUID: result.user.uid, firstName: result.user.displayName?.split(' ')[0]
        }, true));
    };
    return (
        <>
            <div className="main-content">
                <div className="submit-movie-wrapper email-register-wrapper">
                    <div className="container">
                        <div className="submit-movie-content col-md-10 mx-auto">
                            <div className="submit-movie-form email-register-form user-detail-sec ">
                                <Formik
                                    initialValues={{
                                        email: "",
                                        userType: "user",
                                        password: "",
                                        redirectPath: redirectPath,
                                    }}
                                    onSubmit={(values, { setSubmitting }) => {
                                        dispatch(loginUser(values))
                                          .then((res) => {
                                            if (res.status === "fail") {
                                                setIncorrectPass(true);
                                              console.log("Email or password is incorrect!");
                                            } else {
                                               setIncorrectPass(false);
                                            } 
                                          })
                                          .catch((error) => {
                                            console.error("Error occurred:", error);
                                          })
                                          .finally(() => {
                                            setSubmitting(false);
                                          });
                                    }}
                                >
                                    {({ values, handleBlur, handleChange, handleSubmit }) => (
                                        <form className="form mx-auto mb-5 ">

                                            <div className=" mx-auto mb-3 pt-5 pb-4">
                                                <h2 className="text-center " style={{color:'#FFCC00'}} >Sign In</h2>
                                                <h6 className="text-center mt-3" style={{color:'#FFCC00'}}>Welcome back, Please add your details</h6>
                                            </div>

                                            <div className="mb-3">
                                                <div className="flex gap-2">
                                                    <label htmlFor="email" className="form-label fw-bold regiseration-label" >
                                                        Email
                                                    </label>
                                                    {/* <span className="star">*</span> */}
                                                </div>
                                                <input
                                                    type="text"
                                                    className={`form-control m-auto bord  ${incorrectPass ? 'border-danger' : ''}`}
                                                    id="email"
                                                    name="email"
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        setIncorrectPass(false);
                                                    }}
                                                    onBlur={handleBlur}
                                                    defaultValue={values.email}
                                                    placeholder="Enter your email address..."
                                                />
                                            </div>
                                            <div className="mb-1">
                                                <div className="flex gap-2">
                                                    <label htmlFor="password" className="form-label fw-bold regiseration-label"  >
                                                        Password
                                                    </label>
                                                    {/* <span className="star">*</span> */}
                                                </div>
                                                <div className="position-relative">
                                                    <input
                                                        type={passwordType}
                                                        className={`form-control bord ${incorrectPass ? 'border-danger' : ''}`}
                                                        id="password"
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            setIncorrectPass(false);
                                                        }}
                                                        onBlur={handleBlur}
                                                        defaultValue={values.password}
                                                        name="password"
                                                        placeholder="Enter your password..."
                                                    />

                                                    {/* <div className="position-absolute top-0 end-0  pt-1" >
                                                        <div onClick={togglePassword}>
                                                            {passwordType === "password" ? (
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="16"
                                                                    height="16"
                                                                    className="bi bi-eye-slash"
                                                                    viewBox="0 0 16 16"
                                                                    fill="#3868b6"
                                                                >
                                                                    <path
                                                                        d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                                                                    <path
                                                                        d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                                                                    <path
                                                                        d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                                                                </svg>
                                                            ) : (
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="16"
                                                                    height="16"
                                                                    className="bi bi-eye"
                                                                    viewBox="0 0 16 16"
                                                                    fill="#3868b6"
                                                                >
                                                                    <path
                                                                        d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                                                    <path
                                                                        d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                                                </svg>
                                                            )}
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                            <div className="text-end" >
                                                <small>
                                                    <NavLink
                                                        to={"/forgetpassword"}
                                                        // onClick={(e) => e.preventDefault()}
                                                        className="modal-links"
                                                        // data-bs-toggle="modal"
                                                        data-bs-target="#forgotmodal">
                                                        ForgetPassword
                                                    </NavLink>
                                                </small>
                                            </div>
                                            <div className="m-auto mt-4 mb-2" >
                                                <ModalBtn
                                                    modalBtnType={"button"}
                                                    onClick={handleSubmit}
                                                    modalBtnTitle={"Sign In"}
                                                    // btnClass={"btn-lg login-xl-btn "}
                                                    

                                                />
                                            </div>
                                            <div className="register-user-content">
                                                <div className="register-user-content-outer  col-sm-12 ">
                                                    {/* <Link to="/register" className="nav-link text-dark regiter-btn"> */}
                                                    <div className="flex flex-row justify-content-center">
                                                        <div style={{ color: "white" }} className="w-50">
                                                            <hr />
                                                        </div>
                                                        <span className="ms-3 me-3" style={{ fontSize: "14px", marginTop: '4px', color: "white" }}>or</span>
                                                        <div style={{ color: "white" }} className="w-50">
                                                            <hr />
                                                        </div>
                                                    </div>
                                                    </div>

                                                    <div className="flex flex-row justify-content-center gap-3 mt-2" >
 
                                                    <button
                                                            type="button"
                                                            onClick={signInWithGoogle}
                                                            style={{
                                                           
                                                                borderRadius: "64px",
                                                                border: "0.5px solid #ced4da",
                                                                padding:"1px 1px",
                                                               
                                                            }}
                                                        >
                                                            <FcGoogle className="register-icon"  style={{fontSize:"26px"}} />
                                                            {/* <span>Signup with Google</span> */}
                                                        </button>
 
                                                        <button
                                                            type="button"
                                                            // variant="register-form-field col-sm-12 mb-3"
                                                            onClick={signInWithFacebook}
                                                            style={{
                                                                // Adjust the width as needed
                                                                borderRadius: "64px",
                                                                border: "0.5px solid #ced4da",
                                                                padding:"1px 1px",
                                                            }}
                                                        >
                                                            <FaFacebook
                                                                className="register-icon register-icon-facebook  "  style={{fontSize:"26px"}}/>
                                                            {/* <span>Signup with Facebook</span> */}
                                                        </button>
                                                </div>
                                            </div>
                                            <div className="text-center mt-4">
                                                <small
                                                    className="form-text"
                                                    data-bs-dismiss="modal"
                                                    aria-label="Close">
                                                    Not Registered? <span className="fw-bold form-text">Please Sign Up Here.</span>
                                                    {/* <NavLink to="/register" className="modal-links">
                                                        Register now
                                                    </NavLink> */}
                                                    
                                                </small>
                                            </div>
                                            <div style={{ textDecoration: 'none' }} className="mt-4 text-center">
                                                <NavLink to="/register" type="button" className="col-md-4 signupbtn" style={{ textDecoration: 'none' }}>
                                                    <h6 style={{ fontSize: '14px',color:'#5A0223', fontWeight: '600'}}>Sign Up</h6>
                                                </NavLink>
                                            </div>

                                        </form>
                                    )}
                                </Formik>        
                            </div>
                        </div>
                    </div>
                </div>
            </div>              
        </>
    );
}

export default Login;
