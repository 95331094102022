import React, {useEffect, useRef} from "react";
import CustomSlider from "../../Components/Helper/CustomSlider";
import Crousel from "../../Components/Helper/Crousel";
import Register from "../../Components/Helper/Register";
import {connect, useDispatch, useSelector} from "react-redux";
import {
    topTrandingMovies,
    comingMovies,
    recentMovie, updatedRentedList,
} from "../../Redux/Actions/movies";
import CommonMovieCard from "../../Components/CommonMovieCard/CommonMovieCard";
import RecentMoviesCrousel from "../../Components/RecentMoviesCrousel/RecentMoviesCrousel";
import ReferNow from "../../Components/Helper/ReferNow";
import ReferPopup from "../../Components/Helper/Modal/ReferPopup";
import { useState } from "react";
import DownloadScreen from "../../Components/Helper/downloadScreen";
import WhyDhakad from "../../Components/Helper/whyDhakad";
import Avengers from "../../Components/Helper/avengers";


const loggedInId = localStorage.getItem('id');
const Home = () => {
    const dispatch = useDispatch();
    // tranding movie
    useEffect(() => {
        dispatch(topTrandingMovies());
        dispatch(comingMovies());
        dispatch(recentMovie());
        dispatch(updatedRentedList());
    }, [dispatch]);
    const getUserDataById = useSelector((state) => state?.user);
    const referralCode = getUserDataById?.user?.data?.user.referralCode;
    const [popup,Setpopup] = useState(false)
    // comming movie
    const comingMovieList = useSelector((state) => state.movie_list);

    const comingMoviesData = comingMovieList?.coming_movie?.movies
        ? comingMovieList?.coming_movie?.movies
        : "";
  
 function openReferModal(){
    Setpopup(true);
    document.body.classList.add("disable-scroll");
 }


 const handleClose = () => {
    Setpopup(false);
    document.body.classList.remove("disable-scroll");
  }


    return (
        <React.Fragment >
            
        <CustomSlider/>
        <RecentMoviesCrousel/>
        
        {comingMoviesData.length>0 && <Crousel
            infinite={comingMoviesData.length >= 5 ? true : false}
            crouselHeading="| Coming Soon">
            {comingMoviesData
                ? comingMoviesData?.map((item, index) => {
                    return (
                        <CommonMovieCard
                            movieItem={item}
                            key={item.id}
                            loggedInId={loggedInId}
                        />
                    );
                })
                : ""}
        </Crousel>
        }
        <Register/>
         {popup && <ReferPopup  handleClose={handleClose}  referralCode={referralCode}/> }
        <ReferNow openReferModal={openReferModal}/>  
        <WhyDhakad/>
        <Avengers/>
        <DownloadScreen/>
    </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        is_loading: state.movie_list.is_loading,
    };
};

export default connect(mapStateToProps, {
    topTrandingMovies,
    comingMovies,
    recentMovie,
    updatedRentedList,
})(Home);
