import React, {useState} from "react";
import TheatersIcon from "@material-ui/icons/PlayArrow";
import FullScreenTrailer from "./Modal/FullScreenTrailer";
import Tooltip from '@material-ui/core/Tooltip';

const FullTrailerButton = (props) => {
    const [show, setShow] = useState(false);
    const handleShow = (event) => {
        event.stopPropagation();
        setShow(true)
    };
    const handleClose = (event) => {
        event.stopPropagation();
        setShow(false);
    }
    return <>
        <Tooltip title="Play trailer">
            <button
                onClick={handleShow}
                className={'icon-btn ' + props.className}>
                <TheatersIcon className={props.iconClassName || 'icon-20'}/>
            </button>
        </Tooltip>

        <FullScreenTrailer show={show} handleClose={handleClose} trailerLink={props.trailerLink}/>
    </>
}

export default FullTrailerButton;