import React from "react";
import Slider from "react-slick";
 import "swiper/swiper-bundle.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../Helper/Style.css";
import "../Helper/Responsive.css";
export default function Crousel(props) {
  let settings = {
   
       

    infinite: props.infinite,
    initialSlide: 0,
    slidesToShow: 3,
    swipeToSlide: true,
    emulateTouch: true,
    slidesToScroll: 1,
    cssEase: "linear",
    autoplay:true,
    mobileFirst:true,
    responsive: [

      {
        breakpoint: 3020, // tablet breakpoint
        settings: {
          slidesToShow: 3,
          arrows: false,
        },
      },


      {
        breakpoint: 2040, // tablet breakpoint
        settings: {
          slidesToShow: 2,
          arrows: false,
        },
      },

      {
        breakpoint: 1840, // tablet breakpoint
        settings: {
          slidesToShow: 2,
          arrows: false,
        },
      },


      {
        breakpoint: 1260, // tablet breakpoint
        settings: {
          slidesToShow: 2,
          arrows: false,
        },
      },


      {
        breakpoint: 760, // tablet breakpoint
        settings: {
          slidesToShow: 2,
          arrows: false,
        },
      },


      {
        breakpoint: 248, // tablet breakpoint
        settings: {
          slidesToShow: 1,
          arrows: false,
        },
      },


     

      ],

  };
    return (
      <React.Fragment>
        <div className="crousel-wrapper" >
          <div className="container-fluid ">
            <div className="crousel-card-type">
              <div className="crousel-card-type-main-heading section-heading section-heading-band" style={{justifyContent:"center"}}>
                <h3>{props.crouselHeading}</h3>
              </div>
            </div>
            <Slider {...settings}>{props.children}</Slider>
          </div>
        </div>
      </React.Fragment>
    );
  }
     
      
     
      

     

     

      
      

     

    // {
      //   breakpoint: 1441, // tablet breakpoint
      //   settings: {
      //     slidesToShow: 3,
      //   },
      // },
      // {
      //   breakpoint: 1600, // tablet breakpoint
      //   settings: {
      //     slidesToShow: 5,
      //   },
      // },
      // {
      //   breakpoint: 1920, // tablet breakpoint
      //   settings: {
      //     slidesToShow: 5,
      //   },
      // },
      // {
      //   breakpoint: 2040, // tablet breakpoint
      //   settings: {
      //     slidesToShow: 6,
      //   },
      // },
      // {
      //   breakpoint: 2280, // tablet breakpoint
      //   settings: {
      //     slidesToShow: 6,
      //   },
      // },
      // {
      //   breakpoint: 2420, // tablet breakpoint
      //   settings: {
      //     slidesToShow: 6,
      //   },
      // },
      // 
      // 
      // {
      //   breakpoint: 3020, // tablet breakpoint
      //   settings: {
      //     slidesToShow: 7,
      //   },
      // },
  
