import React, {useEffect} from "react";
import CommonMovieCard from "../CommonMovieCard/CommonMovieCard";
import Crousel from "../Helper/Crousel";
import {connect, useDispatch, useSelector} from "react-redux";
import {recentMovie, updatedRentedList, updateWishlist} from "../../Redux/Actions/movies";

const loggedInId = localStorage.getItem('id')
const RecentMoviesCrousel = ({heading})=>{
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(recentMovie());
        // to fetch the updated wishlist
        dispatch(updateWishlist());
        dispatch(updatedRentedList());
    }, [dispatch]);
    // recent movie
    const movieListStore = useSelector((state) => state.movie_list);

    const recentMovies = movieListStore?.recent_movie?.movies
        ? movieListStore?.recent_movie?.movies
        : "";
    return <Crousel 
        infinite={recentMovies.length >= 5 ? true : false}
        crouselHeading={heading || 'Movies'}>
        {recentMovies ? recentMovies?.map((item, index) => (<CommonMovieCard
            movieItem={item}
            key={item.id}
            loggedInId={loggedInId}
        />)) : ""}
    </Crousel>
}

const mapStateToProps = (state) => {
    return {
        is_loading: state.movie_list.is_loading,
    };
};

export default connect(mapStateToProps, {
    recentMovie,
    updateWishlist,
    updatedRentedList,
})(RecentMoviesCrousel)