import React from "react";
import "./AboutCompany.css"

const AboutCompany = () => {
  return (
    <React.Fragment>
      <div className=" about-container">
        <div className="container">
          <div className="about-wrapper">
            <div className="about-outer col-md-12">
              <div className=" col-md-12 " style={{ marginTop:"4%", marginBottom:"2%", display:"flex", justifyContent:"center"}}>
                <h2 className="about-main-heading AboutCompanyTitle">ABOUT COMPANY</h2>
              </div>
              <div className="about-content">
                <div className="about-content-heading">
                  <h3 className="about-title AboutCompanySubtitle">WHY DHAAKAD CINEMA?</h3>
                </div>
                <div className="about-content-description">
                  <p className="about-description">
                  Introducing Dhaakad Cinema, India's first online theater,
                   where every selection is a masterpiece worth your time and money.
                    We're on a mission to spotlight exceptional films, web series, plays,
                     and comedy shows. Our aim is to provide a voice and platform for talented
                      filmmakers whose movies/web series may not have the star power or backing
                       of big production houses to secure traditional theater screenings or licensing
                        deals with major OTT platforms. We believe that you, the audience deserve exceptional
                         content and only you should have the power to decide and create new stars.
                  </p>

                  <p className="about-description">Led by filmmaker Earl Yadav, our platform is built on transparency and a commitment to 
                    showcasing only the finest content. We believe in quality over quantity, curating a 
                    selection that guarantees entertainment worth your time. At Dhaakad Cinema, we stand 
                    against narratives that undermine Indian values, championing stories that celebrate our culture.
                    </p>
                    <p className="about-description">We're dedicated to nurturing new talent, providing a platform for aspiring filmmakers to shine. Through our unique rating system, ranging from "Anaar" to "Nuclear," we guide you to content that resonates with your preferences, ensuring a fulfilling cinematic experience every time.</p>
                </div>
              </div>
            
             
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AboutCompany;
