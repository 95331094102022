import React from 'react'
import "../Helper/Style.css";
import avengerImg from '../../Assets/Images/avenger.svg'; 
import { Link } from 'react-router-dom';
import {connect, useDispatch, useSelector} from "react-redux";
function AvengerScreen() {

  const dispatch = useDispatch();

  const token = localStorage.getItem("token");
    return (
        <React.Fragment>  
          <hr style={{width:"90%" , margin:"auto" , marginBottom:"10px"}}/> 
              <div className="avenger-body align-item-center">
                  <div className="avenger">
            <div className='avenge-heading'>      <h3><span>Launched 10 new Actors /</span><span>Actresses from Delhi-NCR </span> </h3>  </div>  
                    <p> <span>This movie ZIA was shot mostly in Delhi-NCR region. Majority</span>
                      <span> (95%) of movie was shot in Greater Noida</span></p>
                  </div>
                  <div  style={{display:'flex'}}>
                    <img className="avengerImg text-center" src={avengerImg} alt="avenger" /> 
                  </div>   
                  </div>  
                  <hr  style={{width:"90%" , margin:"auto" , marginBottom:"10px"}}/>        

        </React.Fragment>
  )
}

export default connect(null)(AvengerScreen) ;