import React from "react";
import "../Helper/Style.css";
import UniqueBtn from "../UniqueBtn.jsx";
import { NavLink } from "react-router-dom"; // Import NavLink

const Register = () => {
  const token = localStorage.getItem("token");
  const isAuthenticated = token;

  return (
    <React.Fragment>
      <div className="registration-banner mt-5" id="masthead-registration-swiper">
        <div className="container container-bottom">
          <div className="registration-content col-md-6">
            <div className="join-banner">
              <div className="reges-banner pt-3 pb-2">
                <h3 style={{paddingLeft:'10px'}}>
                  We support new filmmakers. 
                </h3>
                <p>We encourage new actors/actresses here.</p>
              </div>
              <div className="join-btn">
                {isAuthenticated ? (
                  <UniqueBtn
                    title="Join Us"
                    icon=""
                    // iconsClass={"bg-warning"}
                    disabled={false}
                  />
                ) : (
                  <NavLink to="/join-us">
                    <UniqueBtn title="Join Us" icon=""
                    iconsClass={"bg-warning"} />
                  </NavLink>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Register;
