import React from 'react'
import "../Helper/Style.css";
import download from '../../Assets/Images/download.svg'; 
import {connect, useDispatch, useSelector} from "react-redux";
import { MODAL_OPEN,MODAL_CLOSE } from "../../Redux/Actions/type";
function downloadScreen({openReferModal}) {

//   const dispatch = useDispatch();

  const token = localStorage.getItem("token");
    return (
        <React.Fragment>   
              <div className=" refernow-body">
                  <img className="mobileImg" src={download} alt="ReferNow" />
                  <div className="refer-now h3  ">
                    <h3 > <span> Download our mobile application</span></h3>
                    <p > <span>To watch movies on your phone seamlessly, please use our app</span> </p>
                    <button   className=' hover-green '>Download</button>     
                  </div>
                  
              </div>  
        </React.Fragment>
  )
}

export default connect(null, {MODAL_OPEN,MODAL_CLOSE})(downloadScreen) ;
